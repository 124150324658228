
:root {
  --background-color: #f7f7f7;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  z-index: 1;
  transition: background-color 1000ms linear;
}

main {
  flex: 1 0 auto !important;
  z-index: 1;
  padding-top:30px;
  background-color: var(--background-color);
}

header, footer {
  /* background:#001529 !important;
  color:rgba(255, 255, 255, 0.65) !important; */
}

header {
  padding: 0 25px !important;
}

header .ant-menu {
  display: inline-block;
}

header .logo {
  display:inline-block;
  padding-right: 20px;
}

header .logo img {
  max-height: 35px;
}

@media only screen and (max-width: 640px) {
  header .logo {
    display: none;
  }
}

main.ant-layout-content {
  padding:0 !important;
  position: relative;
}

main .site-layout-content {
  /* margin:25px auto; */
  max-height: 100%;
  /* overflow-y: hidden; */
}

main .loading-screen {
  z-index: 9;
  background-color: var(--background-color);
  user-select: none;
}

.ant-menu-submenu-title {
  padding-left:20px !important;
}

.ant-menu-item {
  padding-left: 45px !important;
}

.ant-menu-item a {
  font-size: 13.5px
}

.ant-tooltip-inner {
  font-size:11px !important;
  font-weight:400 !important;
  background-color:rgba(0,0,0,0.85) !important;
}

.internal-notice {
  padding-left:11px;
  padding-bottom:2.5px;
  width:100%;
  font-size:11px;
  font-weight:500;
  color:rgb(249,143,143);
  cursor: help;
}

.danger .ant-tooltip-inner {
  font-weight:600 !important;
  color:#fd4e4e;
}

.warn .ant-tooltip-inner {
  color:#ff9c07;
}

.ant-menu-item figure {
  position: absolute;
  right:-1.75em; top:-2px;
  width: 0.95em; height:0.95em;
  margin:0; padding:10px;
  z-index: 1;
  cursor: help;
}

.ant-menu-item .anticon {
  position: absolute;
  left:calc(45% - 0.4em); top:calc(50% - 0.4em);
}

.ant-menu-item .anticon svg {
  width:0.9em;
  height:0.9em;
}

.ant-menu-item .anticon.warn svg {
  fill:#ff9c07;
}

.ant-menu-item .anticon.danger svg {
  fill:#f99393;
}

.ant-menu-item .anticon.it-blue svg {
  fill:#0eb1ff;
}

a > .ant-tag {
  cursor: pointer;
  user-select: none;
}


.text-center { text-align: center; }

.form-error {
  color:transparent !important;
  transition: 0.25s ease-in-out;
  font-size: 12px;
}

.form-error.visible {
  color:#ff4d4f !important;
  transition: 0.25s ease-in-out;
}



#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 9999 !important;
}

.tumbleweed-container {
  position: absolute;
  bottom: 0;
  left:0;
  width:100%;
  height:100%;
}
.tumbleweed {
  position: relative;
  top: 220px;
  width: 45px;
  height: 45px;
  margin-left:-50px;
  padding:0;
  background: url('https://assets.interactive-online.com/cloud/w_45/global/tumbleweed.png') no-repeat;
  background-size: 100%;
  opacity: 0.6;
}

.tumbleweed {
  animation: tumbleweed linear 8s;
  animation-iteration-count: infinite;
}

@keyframes tumbleweed{
  0% {
    transform:  translate(0px,45px)  rotate(0deg) ;
  }
  9% {
    transform:  translate(90px,5px)  rotate(130deg) ;
  }
  17% {
    transform:  translate(160px,45px)  rotate(250deg) ;
  }
  26% {
    transform:  translate(270px,15px)  rotate(360deg) ;
  }
  34% {
    transform:  translate(360px,45px)  rotate(490deg) ;
  }
  42% {
    transform:  translate(480px,30px)  rotate(600deg) ;
  }
  50% {
    transform:  translate(601px,45px)  rotate(720deg) ;
  }
  100% {
    transform:  translate(601px,45px)  rotate(720deg) ;
  }
}
